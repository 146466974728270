import React from 'react'

import { useRoutes } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import LibUiGlobalStyle from '@nickel/foundations/components/config/GlobalStyle'
import nickel from '@nickel/foundations/themes'

import { routes } from './routes'
import Styled from './styles'

const Router = () => useRoutes(routes)

const App = () => (
    <ThemeProvider theme={nickel}>
        <LibUiGlobalStyle />
        <Styled.Global />
        <Router />
    </ThemeProvider>
)

export default App
