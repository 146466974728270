import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@nickel/core-ui/components/Button'
import ExitTopRight from '@nickel/ui/components/icons/ExitTopRight'
import hamburger from '@nickel/ui/components/icons/HamburgerMenu'

import { InactivityWatcher } from '../../components/InactivityWatcher'
import { NavBar } from '../components/NavBar'

import Styled from './Legal.styles'

export const Legal = (props: { lang: string }) => {
    const [showNavBar, setShowNavBar] = React.useState(false)

    const { t } = useTranslation()

    const legalLink = `${window.REACT_APP_LEGAL_DOCUMENTS_URL}/${props.lang}`
    const burgerClick = () => {
        setShowNavBar(!showNavBar)
    }
    const qrCode = `/images/qrCode/${props.lang}/qrCode.svg`
    const home = `/${props.lang}`

    return (
        <InactivityWatcher url={home}>
            <Styled.Section>
                <Styled.MenuButton>
                    <Button Icon={hamburger} testId="" text="" variant="secondary" onClick={burgerClick} />
                </Styled.MenuButton>
                <NavBar lang={props.lang} isShown={showNavBar} handleClick={burgerClick} />
                <Styled.NickelImage>
                    <div>
                        <img src="/images/Logo.svg" alt="Logo nickel" />
                    </div>
                </Styled.NickelImage>
                <div>
                    <h1>{t('legal:legalDocuments')}</h1>
                    <p>{t('legal:legalDocumentsSubtitle')}</p>
                    <img src={qrCode} alt="qr code" />
                    <Styled.Divider>
                        <Styled.Bar />
                        {t('legal:or')}
                        <Styled.Bar />
                    </Styled.Divider>
                    <p>
                        <a href={legalLink}>{t('legal:legalDocumentsLink')}</a>
                        &nbsp;
                        <ExitTopRight />
                    </p>
                </div>
            </Styled.Section>
        </InactivityWatcher>
    )
}
