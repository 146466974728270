import React from 'react'

import { Navigate } from 'react-router-dom'

import { supportedLocalePath } from '../i18n'
import { Layout } from '../layout'
import { Home, Legal, Sleep, NotFound } from '../screens'

const localeHome = supportedLocalePath.map((locale) => ({
    path: `${locale}`,
    element: <Home lang={locale} />
}))
const localeLegal = supportedLocalePath.map((locale) => ({
    path: `${locale}/legal`,
    element: <Legal lang={locale} />
}))
const localeSleep = supportedLocalePath.map((locale) => ({
    path: `${locale}/sleep`,
    element: <Sleep lang={locale} />
}))
export const routes = [
    {
        path: '*',
        element: <NotFound />
    },
    { path: '/', element: <Navigate to="/fr-fr" /> },
    {
        path: '/',
        element: <Layout />,
        children: localeHome.concat(localeLegal)
    }
].concat(localeSleep)
