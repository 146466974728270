import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { addLibTranslations as addLibCoreUiTranslations } from '@nickel/core-ui/lib/i18n'
import i18n, { addTranslations, Dictionnary } from '@nickel/i18n'
import { SupportedLocale } from '@nickel/i18n/lib/types'

import deDE from './languages/de_DE'
import enUS from './languages/en_US'
import esES from './languages/es_ES'
import frBE from './languages/fr_BE'
import frFR from './languages/fr_FR'
import nlBE from './languages/nl_BE'
import ptPT from './languages/pt_PT'
import root from './languages/root'

const detector = new LanguageDetector()
detector.addDetector({
    name: 'customPath',
    lookup(options: DetectorOptions) {
        let found
        if (typeof window !== 'undefined') {
            const language = window.location.pathname.match(/\/([a-zA-Z-]*)/g)
            if (language instanceof Array) {
                if (typeof options.lookupFromPathIndex === 'number') {
                    if (typeof language[options.lookupFromPathIndex] !== 'string') {
                        return undefined
                    }
                    found = language[options.lookupFromPathIndex].replace('/', '')
                } else {
                    found = language[0].replace('/', '')
                }
            }
        }
        if (found?.toLowerCase() === 'en-be') {
            return 'en-us'
        }
        return found
    }
})

i18n.use(initReactI18next)
    .use(detector)
    .init({ detection: { order: ['customPath'] }, nonExplicitSupportedLngs: true, fallbackLng: 'root' })

export const supportedLocalePath = ['fr-fr', 'en-be', 'es-es', 'de-de', 'fr-be', 'nl-be', 'pt-pt']

const dictionnary: Dictionnary = {
    [SupportedLocale.FR_FR]: frFR,
    [SupportedLocale.EN_US]: enUS,
    [SupportedLocale.ES_ES]: esES,
    [SupportedLocale.DE_DE]: deDE,
    [SupportedLocale.FR_BE]: frBE,
    [SupportedLocale.NL_BE]: nlBE,
    [SupportedLocale.PT_PT]: ptPT,
    root
}

addTranslations(dictionnary)
addLibCoreUiTranslations()

export default i18n
