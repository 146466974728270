import React from 'react'

import { useNavigate } from 'react-router-dom'

import Styled from './Sleep.sytles'

export const Sleep = (props: { lang: string }) => {
    const navigate = useNavigate()

    // Mutualise les videos belges.
    function getVideoLink(lang: string) {
        const belgiumLang = ['nl-be', 'fr-be', 'en-be']
        if (belgiumLang.includes(lang.toLowerCase())) {
            return `/videos/fr-be/video.mov`
        }
        return `/videos/${props.lang.toLowerCase()}/video.mov`
    }
    return (
        <Styled.VideoContainer onClick={() => navigate('..', { relative: 'path' })}>
            <video autoPlay muted height="100%" loop playsInline>
                <source src={getVideoLink(props.lang)} type="video/mp4" />
                <track default kind="captions" />
            </video>
        </Styled.VideoContainer>
    )
}
