import styled from 'styled-components'

const Section = styled.section`
    margin: auto;
    text-align: center;
    span {
        margin-top: -10px;
        padding-top: -10px;
    }
    p a {
        text-decoration: underline;
    }
`

const NickelImage = styled.section`
    margin: auto;
    height: 10%;
    width: 100%;
`

const Divider = styled.section`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
`
const Bar = styled.section`
    height: 1px;
    margin: 20px;
    background-color: #e1e2e5;
    width: 100%;
`

const MenuButton = styled.section`
    position: fixed;
    top: 0px;
    left: 0px;
    button {
        border-style: none;
    }
`

export default {
    MenuButton,
    Section,
    NickelImage,
    Divider,
    Bar
}
