import styled from 'styled-components'

const Section = styled.section`
    padding: 30px;
    style: none;
    position: fixed;
    top: 0px;
    left: 0px;
    text-align: left;
    background-color: white;
    width: 350px;
    height: 450px;
    * {
        font-size: 1.1rem;
    }
    h2 {
        margin-top: 20px;
    }
`

const Divider = styled.section`
    height: 1px;
    margin: 0px;
    background-color: #e1e2e5;
    margin-top: 15px;
`

const Menu = styled.section`
    width: 100%;
    font-weight: bold;
    margin-bottom: 40px;
    h2 {
        margin-top: 0px;
    }
    button {
        position: absolute;
        right: -10px;
        border-style: none;
    }
`

const Home = styled.section`
    float: top;
    h2 {
        font-weight: bold;
    }
    svg {
        margin-right: 10px;
    }
`

const Category = styled.section`
    font-size: 0.8rem;
    margin-top: 40px;
    text-transform: uppercase;
    font-weight: normal;
    color: gray;
`

const Documents = styled.section``

const Formation = styled.section`
    position: absolute;
    bottom: 30px;
    h2 {
        a {
            font-weight: 200;
            text-decoration: underline;
        }
    }
`

export default { Section, Divider, Menu, Category, Home, Documents, Formation }
