import { createGlobalStyle } from 'styled-components'

import '@csstools/normalize.css'

const Global = createGlobalStyle`
    body {
        margin: 0;
        height: 100%;
        overflow: hidden;
    }
`
export default {
    Global
}
