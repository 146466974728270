import React, { Suspense } from 'react'

import ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import i18n from './i18n'

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
        <BrowserRouter>
            <Suspense fallback={<div />}>
                <App />
            </Suspense>
        </BrowserRouter>
    </I18nextProvider>,
    document.getElementById('root')
)
