import React from 'react'

import { Outlet } from 'react-router-dom'

import Styled from './Layout.styles'

export const Layout = () => {
    return (
        <Styled.Container>
            <Styled.Main>
                <Outlet />
            </Styled.Main>
        </Styled.Container>
    )
}
