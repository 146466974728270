import styled from 'styled-components'

import { Spacing, FontSize, Breakpoint } from '@nickel/foundations/tokens'

const Container = styled.section`
    padding: ${Spacing.XWIDE};
    display: grid;
    background-color: #fafcff;
    height: 100vh;
    font-size: ${FontSize.MEDIUM};
    span {
        font-family: Encode Sans Condensed;
        font-weight: 800;
    }
    h1 {
        margin: ${Spacing.WIDE} 0px 0px 0px;
        font-family: Encode Sans Condensed;
        font-weight: 800;
    }
    a {
        text-decoration: none;
        color: black;
    }
`

const Main = styled.main`
    justify-self: center;
    width: 60%;
    @media (min-width: ${Breakpoint.DESKTOP}) {
        width: 40%;
    }
`

export default {
    Container,
    Main
}
