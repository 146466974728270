import styled from 'styled-components'

const VideoContainer = styled.section`
    overflow: hidden;
    width: 100%;
    height: 100vh;
    margin: auto;
    background-color: black;
    display: flex;
    justify-content: center;
    video {
        margin: auto;
        object-fit: cover;
        object-align: center center;
    }
`

export default {
    VideoContainer
}
