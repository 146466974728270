import styled from 'styled-components'

import { Spacing, FontSize } from '@nickel/foundations/tokens'

const Section = styled.section`
    margin: auto;
    text-align: center;
`

const NickelImage = styled.section`
    margin: auto;
    height: 10%;
    width: 100%;
`
const PartnerLink = styled.section`
    style: none;
    position: fixed;
    bottom: 50px;
    left: 50px;
    h1 {
        width: 100%;
        font-size: 0.9em;
        text-decoration: underline;
        font-weight: 500;
    }
`

const LinkBox = styled.section`
    background-color: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 0px;
    box-shadow: 0px 4px 16px 0px #191f291a;
    margin: ${Spacing.XWIDE} auto;
    h1 {
        padding: ${Spacing.MEDIUM} 0px ${Spacing.WIDE} 0px;
    }
    div {
        display: flex;
        font-size: ${FontSize.MEDIUM};
        flex-grow: 1;
        h1 {
            margin: auto auto auto ${Spacing.MEDIUM};
            padding: ${Spacing.XWIDE} 0px ${Spacing.XWIDE} 0px;
        }
        img {
            margin: auto ${Spacing.MEDIUM} auto auto;
            width: 7.422vw;
            height: 7.028vh;
            gap: 0px;
            opacity: 0px;
        }
    }
`
const MenuButton = styled.section`
    position: fixed;
    top: 0px;
    left: 0px;
    button {
        border-style: none;
    }
`

export default {
    Section,
    NickelImage,
    PartnerLink,
    LinkBox,
    MenuButton
}
